import React, { useState } from 'react';
import commonStyles from "../../../style/common.module.css";
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import { BadgeAssignment, isEvaluatorEditable } from '../../model/badge-assignment';
import dayjs from 'dayjs';
import { getNumber, isValidHttpUrl } from '../../../../utils/helper/string-helper';
import BaseForm from '../../../../component/form/base-form';
import { updateEvaluatorAssignment } from '../../service/assignment-service';
import { useToast } from '../../../../context/toast/toast-provider';
import { evaluatorUpdateFieldDefinitions } from './evaluator-update-field-definition';
import MenuAction from '../../../../component/menu/menu-action';

export default function EvaluatorJournalBadgeContent({
    initialBadge,
    updateBadge
}: {
    initialBadge: BadgeAssignment,
    updateBadge(badgeAssignment: BadgeAssignment): void
}) {
    const [submitting, setSubmitting] = useState(false);
    const [editActive, setEditActive] = useState<boolean>(false);
    const [badge, setBadge] = useState<BadgeAssignment>(initialBadge);

    const toast = useToast();

    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const customValidation = (badgeAssignment: BadgeAssignment, errors: any) => {
        const commentGiven = (badgeAssignment.comment !== undefined && badgeAssignment.comment !== '');
        const ratingGiven = (badgeAssignment.rating !== undefined && badgeAssignment.rating !== '' && Number(badgeAssignment.rating) !== 0);

        if (!commentGiven && !ratingGiven) {
            return {
                ...errors,
                rating: `Please provide either a comment or set a review rating`,
                comment: `Please provide either a comment or set a review rating`
            };
        }
        return errors
    }

    const handleSubmit = (badgeAssignment: BadgeAssignment) => {
        setSubmitting(true);

        badgeAssignment.rating = badgeAssignment.rating?.toString();

        updateEvaluatorAssignment(badgeAssignment).then(result => {
            toast.addToast(`Great work! Session submission review ${result?.badgeName} has been updated`, "success");
            if (result !== undefined) {
                result.moduleId = badge.moduleId;
                updateBadge(result);
            }

            setEditActive(false);
            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to update session submission review`, "error");
            setEditActive(false);
            setSubmitting(false);
        });
    }

    const getSessionPath = () => {
        var isURL = isValidHttpUrl(initialBadge.workingBadgePath);
        if (isURL === true) {
            return <a rel="noopener noreferrer" href={initialBadge.workingBadgePath} target="_blank">{initialBadge.workingBadgePath}</a>
        } else {
            return <span style={{ overflowWrap: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: (initialBadge.workingBadgePath !== undefined && initialBadge.workingBadgePath !== '') ? initialBadge.workingBadgePath : '<i>No path given</i>' }} >
            </span>
        }
    }

    return (
        <React.Fragment>
            <div className={commonStyles.journal} >
                <Typography>
                    <span className={commonStyles.smr}><b>Submission date: </b></span> {initialBadge.submissionDate !== undefined && <span>{dayjs(initialBadge.submissionDate).format('YYYY-MM-DD')}</span>}
                </Typography>
                <Typography>
                    <span className={commonStyles.smr}><b>Evaluation date: </b></span> {initialBadge.evaluatedDate !== undefined && <span>{dayjs(initialBadge.evaluatedDate).format('YYYY-MM-DD')}</span>}
                </Typography>
                {isEvaluatorEditable(initialBadge) && <Typography>
                    <MenuAction handleEdit={() => setEditActive(!editActive) } />
                </Typography>}
            </div>
            <Typography className={commonStyles.pb}>
                <b>Session instruction: </b><br />
                <span style={{ overflowWrap: 'break-word' }}
                    dangerouslySetInnerHTML={{ __html: (initialBadge.badgeInstruction !== undefined && initialBadge.badgeInstruction !== '') ? initialBadge.badgeInstruction : '<i>Session instruction missing</i>' }} >
                </span>
            </Typography>
            <Typography className={commonStyles.pb}>
                <b>Session path: </b><br />
                {getSessionPath()}
            </Typography>
            {editActive === false && getNumber(initialBadge.rating) > 0 && <Typography className={commonStyles.pb} sx={{ display: 'flex' }} >
                <b>Your rating: </b><span className={commonStyles.sml} >{Array.from({ length: getNumber(initialBadge.rating) }, () => <StarIcon fontSize="small" key={`${Math.random()}-select-icon`} color="primary" />)}</span>
            </Typography>}
            {editActive === false && <Typography className={commonStyles.pb}>
                <b>Your comment: </b><br />
                <span style={{ overflowWrap: 'break-word' }}
                    dangerouslySetInnerHTML={{ __html: (initialBadge.comment !== undefined && initialBadge.comment !== '') ? initialBadge.comment : '<i>No comment given</i>' }} >
                </span>
            </Typography>}
            {editActive === true && <div className={commonStyles.pt} >
                <BaseForm
                    model={badge}
                    setModel={setBadge}
                    fields={evaluatorUpdateFieldDefinitions}
                    customValidation={customValidation}
                    submitEntity={handleSubmit}
                    submitting={submitting}
                    submitButtonText='Update'
                    cancel={() => {
                        setEditActive(false);
                    }}
                />
            </div>}
        </React.Fragment>
    );
}