import React, { useState } from 'react';
import commonStyles from "../../../style/common.module.css";
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import { BadgeAssignment, isStudentEditable } from '../../model/badge-assignment';
import dayjs from 'dayjs';
import { getNumber, isValidHttpUrl } from '../../../../utils/helper/string-helper';
import BaseForm from '../../../../component/form/base-form';
import { deleteStudentAssignment, updateStudentAssignment } from '../../service/assignment-service';
import { useToast } from '../../../../context/toast/toast-provider';
import { studentUpdateFieldDefinitions } from './student-update-field-definition';
import { AssignmentStatus } from '../../enum/assignment-status';
import MenuAction from '../../../../component/menu/menu-action';
import { CourseLevel } from '../../../-education/enum/course-level';
import { getBadgeTerminology } from '../../utils/terminology';

export default function StudentJournalBadgeContent({
    initialBadge,
    updateBadge,
    removeBadge,
    studentCourseLevel
}: {
        initialBadge: BadgeAssignment,
        updateBadge(badgeAssignment: BadgeAssignment): void,
        removeBadge(badgeAssignment: BadgeAssignment): void,
        studentCourseLevel: CourseLevel
}) {
    const [submitting, setSubmitting] = useState(false);
    const [editActive, setEditActive] = useState<boolean>(false);
    const [badge, setBadge] = useState<BadgeAssignment>(initialBadge);

    const toast = useToast();

    const handleSubmit = (badgeAssignment: BadgeAssignment) => {
        setSubmitting(true);

        badgeAssignment.rating = badgeAssignment.rating?.toString();

        updateStudentAssignment(badgeAssignment).then(result => {
            toast.addToast(`Great work! ${getBadgeTerminology(studentCourseLevel)} ${result?.badgeName} submission has been updated`, "success");
            if (result !== undefined) {
                result.moduleId = badge.moduleId;
                updateBadge(result);
            }

            setEditActive(false);
            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to update ${getBadgeTerminology(studentCourseLevel, true)} submission`, "error");
            setEditActive(false);
            setSubmitting(false);
        });
    }

    const getFieldDefinitions = () => {
        studentUpdateFieldDefinitions.find(_ => _.id === "workingBadgePath")!.name = getBadgeTerminology(studentCourseLevel);
        return studentUpdateFieldDefinitions;
    }

    const handleDelete = () => {
        if (badge.workItemId !== undefined && isStudentEditable(badge) === true) {
            deleteStudentAssignment(badge.workItemId).then(() => {
                toast.addToast(`${getBadgeTerminology(studentCourseLevel)} ${badge.badgeName} submisstion has been deleted`, "success");
                removeBadge(badge);
            }).catch(() => {
                toast.addToast(`Unable to delete ${getBadgeTerminology(studentCourseLevel, true)} ${badge.badgeName} submission`, "error");
            });
        } else {
            toast.addToast(`Unable to delete ${getBadgeTerminology(studentCourseLevel, true)} ${badge.badgeName} submission`, "error");
        }
    }

    const getBadgePath = () => {
        var isURL = isValidHttpUrl(initialBadge.workingBadgePath);
        if (isURL === true) {
            return <a rel="noopener noreferrer" href={initialBadge.workingBadgePath} target="_blank">{initialBadge.workingBadgePath}</a>
        } else {
            return <span style={{ overflowWrap: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: (initialBadge.workingBadgePath !== undefined && initialBadge.workingBadgePath !== '') ? initialBadge.workingBadgePath : '<i>No path given</i>' }} >
            </span>
        }
    }

    return (
        <React.Fragment>
            {initialBadge.assignmentStatus !== AssignmentStatus.Dismissed && <div>
                <div className={commonStyles.journal} >
                    <Typography>
                        <span className={commonStyles.smr}><b>Submission date: </b></span> {initialBadge.submissionDate !== undefined && <span>{dayjs(initialBadge.submissionDate).format('YYYY-MM-DD')}</span>}
                    </Typography>
                    {initialBadge.evaluatedDate !== undefined && <Typography>
                        <span className={commonStyles.smr}><b>Evaluation date: </b></span> {initialBadge.evaluatedDate !== undefined && <span>{dayjs(initialBadge.evaluatedDate).format('YYYY-MM-DD')}</span>}
                    </Typography>}
                    {isStudentEditable(initialBadge) && <Typography>
                        <MenuAction
                            handleEdit={() => setEditActive(!editActive)}
                            handleRemove={handleDelete}
                            removeText="Undo submission"
                        />
                    </Typography>}
                </div>
                {getNumber(initialBadge.rating) > 0 && <Typography className={commonStyles.pb} sx={{ display: 'flex' }} >
                    <b>Rating: </b><span className={commonStyles.sml} >
                        {Array.from({ length: getNumber(initialBadge.rating) }, () => <StarIcon fontSize="small" key={`${Math.random()}-select-icon`} color="primary" />)}
                        {initialBadge.canResubmit === true ? ' (Can be resubmitted)' : ''}
                    </span>
                </Typography>}
                {initialBadge.comment !== undefined && initialBadge.comment !== '' && <Typography className={commonStyles.pb}>
                    <b>Evaluator comment: </b><br />
                    <span style={{ overflowWrap: 'break-word' }}
                        dangerouslySetInnerHTML={{ __html: (initialBadge.comment !== undefined && initialBadge.comment !== '') ? initialBadge.comment : '<i>No comment given</i>' }} >
                    </span>
                </Typography>}
                {editActive === false && <Typography className={commonStyles.pb}>
                    <b>{getBadgeTerminology(studentCourseLevel)} path: </b><br />
                    {getBadgePath()}
                </Typography>}
                {editActive === true && <div className={commonStyles.pt} >
                    <BaseForm
                        model={badge}
                        setModel={setBadge}
                        fields={getFieldDefinitions()}
                        submitEntity={handleSubmit}
                        submitting={submitting}
                        submitButtonText='Update'
                        cancel={() => {
                            setEditActive(false);
                        }}
                    />
                </div>}
            </div>}
            {initialBadge.assignmentStatus === AssignmentStatus.Dismissed && <div className={commonStyles.journal}>
                <Typography>
                    <span><i>You have dismissed this {getBadgeTerminology(studentCourseLevel, true)}, did you miss class?</i></span>
                </Typography>
                <Typography>
                    <MenuAction
                        handleRemove={handleDelete}
                        removeText="Undo dismiss"
                    />
                </Typography>
            </div>}
        </React.Fragment>
    );
}